import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/grazie.css'
import '../css/aos.css'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const GraziePage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				className: 'transition-support webkit grazie'
      }}
      title="Grazie | Studio Legale Cirio - Francescon - Stella - Sartori - Maccari"
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="grazie">
				<div className="content clearfix">
          <div className="section-block intro-title-2 header-image redFilter"></div>
					<section className="section-block replicable-content grazie-2">
            <p>Il messaggio è stato inviato correttamente!</p>
            <a data-aos = "fade-up" data-aos-duration = "900" href="/contatti" className="button bkg-pinterest border-hover-pinterest color-white color-hover-pinterest">&lt; Torna ai contatti</a>
					</section>
				</div>
			</div>
		</Layout >
	</>
)

export default GraziePage
